import React from "react";

const Footer = () => {
  return (
    <footer>
      <p className="text-center">
        Urban League of Eastern Massachusetts &copy; 2019{" "}
      </p>
    </footer>
  );
};

export default Footer;
